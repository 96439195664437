import { Injectable } from '@angular/core';
import { PurchaseOrderService } from '@app/core/services/purchaseOrder.service';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import * as PurchaseOrderActions from '@store/purchaseOrder/purchaseOrder.actions';
import * as fromApp from '@store/index';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of } from 'rxjs';
import {
  CreateUserResponse,
  DeepLinkResponse,
  PaymentMethodResponse,
  WebOrderResponse,
} from '@app/shared/models/temp-data-source';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import {
  MessageType,
  NotificationService,
} from '@app/core/services/notification.service';
import { GoogleAnalyticsObject } from '@app/shared/models/deal-info.model';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import * as globals from '@app/shared/models/globals';
import { SessionStorageService } from '@app/core/services/session-storage.service';

@Injectable()
export class PurchaseOrderEffects {
  constructor(
    private actions$: Actions,
    private purchaseOrderService: PurchaseOrderService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private notificationService: NotificationService,
    private gas: GoogleAnalyticsService,
    private sessionStorageService: SessionStorageService
  ) {}

  createBuyerInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.createBuyerInfo),
      mergeMap((action) =>
        this.purchaseOrderService.createUser(action.buyerInfo).pipe(
          map((res) => {
            console.log("in effect")
            const data: CreateUserResponse = res.body;
            data.X_Auth_Token = res.headers.get('x-auth-token');
            this.router.navigateByUrl(AppRoutes.PAYMENT);

            this.notificationService.openSnackBar(
              'Buyer Info',
              'Buyer Info successfully added.',
              MessageType.Success
            );

            if (action.refToken != null || action.refToken != undefined)
              this.store.dispatch(
                PurchaseOrderActions.fetchDeepLinkParams(action.refToken, data)
              );

            return PurchaseOrderActions.buyerInfoCreated(data);
          }),

          catchError((error) => {
            return of(PurchaseOrderActions.buyerInfoCreationFailed());
          })
        )
      )
    )
  );

  editBuyer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.editBuyerInfo),
      mergeMap((action) =>
        this.purchaseOrderService
          .editUser(action.buyerInfo, action.userResponse)
          .pipe(
            map((res) => {
              const data: CreateUserResponse = res.body;
              this.router.navigateByUrl(AppRoutes.ORDER_CONFIRMATION);
              data.X_Auth_Token = action.userResponse.X_Auth_Token;
              this.notificationService.openSnackBar(
                'Buyer Info',
                'Buyer Info successfully updated.',
                MessageType.Success
              );
              return PurchaseOrderActions.editBuyerInfoSuccess(data);
            }),

            catchError((error) => {
              return of(PurchaseOrderActions.editBuyerInfoFailed());
            })
          )
      )
    )
  );

  getDeepLinkParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.fetchDeepLinkParams),
      mergeMap((action) =>
        this.purchaseOrderService
          .fetchDeepLinkParams(action.refToken, action.userResponse)
          .pipe(
            map((res) => {
              const data: DeepLinkResponse = res.body;
              this.sessionStorageService.setReferralResponse(data);
              return PurchaseOrderActions.fetchDeepLinkParamsSuccess(data);
            }),
            catchError((error) => {
              var gao: GoogleAnalyticsObject = {
                userId: action.userResponse.id,
                errorMessage: error.message,
              };
              this.gas.eventEmitter(
                globals.GC_EVENT_WEB_INFLUENCER_REF_ERROR,
                gao
              );
              return of(PurchaseOrderActions.fetchDeepLinkParamsFailed());
            })
          )
      )
    )
  );

  createPromoOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.createPromoOrder),
      mergeMap((action) =>
        this.purchaseOrderService.createPromoOrder(action.promoReqData).pipe(
          map((res) => {
            const data: WebOrderResponse = res;
            this.router.navigateByUrl(AppRoutes.PROMO_COMPLETED);
            return PurchaseOrderActions.webOrderCreated(data);
          }),
          catchError((error) => {
            this.notificationService.openSnackBar(
              error.error.reason.errorCode,
              error.error.reason.message,
              MessageType.Error
            );

            return of(PurchaseOrderActions.webOrderCreationFailed());
          })
        )
      )
    )
  );

  getPromoDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.getPromoDeal),
      mergeMap((action) =>
        this.purchaseOrderService
          .getPromoDeal(action.dealId, action.merchantName)
          .pipe(
            map((res) => {
              if (res.status == 'Draft') {
                this.router.navigateByUrl('');
              }
              return PurchaseOrderActions.getPromoDealCompleted(res);
            }),
            catchError((error) => {
              this.router.navigateByUrl('');
              return of(PurchaseOrderActions.fetchDeepLinkParamsFailed());
            })
          )
      )
    )
  );

  getProtalStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.getPortalStatus),
      mergeMap((action) =>
        this.purchaseOrderService.getPortalStatus().pipe(
          map((res) => {
            this.sessionStorageService.setOrderEnabled(res.portalOrderingEnabled)
            return PurchaseOrderActions.getPortalStatusCompleted(res);
          }),
          catchError(() => {
            return of(PurchaseOrderActions.webOrderCreationFailed());
          })
        )
      )
    )
  );

  createPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.createPaymentMethod),
      mergeMap((action) =>
        this.purchaseOrderService
          .createPaymentMethod(action.cardDetails, action.userResponse)
          .pipe(
            map((res) => {
              const data: PaymentMethodResponse = res;
              this.router.navigateByUrl(AppRoutes.ORDER_CONFIRMATION);

              this.notificationService.openSnackBar(
                'Payment Details',
                'Payment Details successfully added.',
                MessageType.Success
              );
              return PurchaseOrderActions.paymentMethodCreated(data);
            }),

            catchError((error) => {
              var gao: GoogleAnalyticsObject = {
                userId: action.userResponse.id,
                errorMessage: error.message,
              };
              this.gas.eventEmitter(
                globals.GC_EVENT_WEB_ADD_PAYMENT_INFO_ERROR,
                gao
              );
              if (error.status == 401) {
                this.notificationService.openSnackBar(
                  'Session Error',
                  'Session has expired.',
                  MessageType.Error
                );
                this.router.navigateByUrl(AppRoutes.BASKET);
              } else
                this.notificationService.openSnackBar(
                  'Payment Method Error',
                  'We are unable to validate your card.  Please confirm details are correct and try again.',
                  MessageType.Error
                );

              return of(PurchaseOrderActions.paymentMethodCreationFailed());
            })
          )
      )
    )
  );

  followRefCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.followRefCode),
      mergeMap((action) =>
        this.purchaseOrderService
          .followRefCode(action.referralCode, action.userResponse)
          .pipe(
            map((res) => {
              this.store.dispatch(
                PurchaseOrderActions.createWebOrder(
                  action.webOrderDetails,
                  action.userResponse
                )
              );
              return PurchaseOrderActions.followRefCodeSuccess();
            }),

            catchError((error) => {
              return of(PurchaseOrderActions.followRefCodeFailed());
            })
          )
      )
    )
  );

  createWebOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseOrderActions.createWebOrder),
      mergeMap((action) =>
        this.purchaseOrderService
          .createWebOrder(action.webOrderDetails, action.userResponse)
          .pipe(
            map((res) => {
              const data: WebOrderResponse = res;
              this.router.navigateByUrl(AppRoutes.COMPLETE);
              return PurchaseOrderActions.webOrderCreated(data);
            }),

            catchError((error) => {
              if (error.status == 401) {
                this.notificationService.openSnackBar(
                  'Session Error',
                  'Session has expired.',
                  MessageType.Error
                );
                this.router.navigateByUrl(AppRoutes.BASKET);
              } else if (error.status == 409) {
                this.notificationService.openSnackBar(
                  'Stock Error',
                  'Sorry, this item is out of stock. Please try again later.',
                  MessageType.Error
                );
                this.router.navigateByUrl(AppRoutes.BASKET);
              } else
                this.notificationService.openSnackBar(
                  'Web Order Error',
                  'Please try again',
                  MessageType.Error
                );

              return of(PurchaseOrderActions.webOrderCreationFailed());
            })
          )
      )
    )
  );
}
