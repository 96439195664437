import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  BuyerInfo,
  CreateUserResponse,
  DealData,
  PaymentMethodResponse,
} from '@app/shared/models/temp-data-source';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions';
import { Store } from '@ngrx/store';
import * as fromApp from '@store/index';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';
import {
  getBuyerInfoSelector,
  getPaymentResponseSelector,
  getUserResponseSelector,
} from '@app/core/store/purchaseOrder/purchaseOrder.selectors';
import { AngularDeviceInformationService } from 'angular-device-information';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import * as globals from '@app/shared/models/globals';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MerchantQrCodeDialogComponent } from '@app/shared/partials/layouts/dialogs/merchant-qr-code-dialog/merchant-qr-code-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from '@environment';

export declare const Breakpoints: {
  XSmall: string;
  Small: string;
  Medium: string;
  Large: string;
  XLarge: string;
  Handset: string;
  Tablet: string;
  Web: string;
  HandsetPortrait: string;
  TabletPortrait: string;
  WebPortrait: string;
  HandsetLandscape: string;
  TabletLandscape: string;
  WebLandscape: string;
};

@Component({
  selector: 'app-buyer-info-tab',
  templateUrl: './buyer-info-tab.component.html',
  styleUrls: ['./buyer-info-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BuyerInfoTabComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isDesktop: boolean = this.deviceInformationService.isDesktop();
  @Input() stepper: MatStepper;
  @Output() formValidator = new EventEmitter<boolean>();

  @ViewChild('recaptchaElem') recaptchaElem: RecaptchaComponent; // Add ViewChild

  isSubmitBtnDisabled = false;

  isNext: boolean;

  recaptchaResponse: string | null = null; // Add this line

  notifier = new Subject<void>();

  buyerFormGroup: FormGroup;

  recipientEmailSub: Subscription;

  buyerInfo: BuyerInfo;
  buyerInfo$: Observable<BuyerInfo>;

  userResponse: CreateUserResponse;
  userResponse$: Observable<CreateUserResponse>;

  paymentInfo: PaymentMethodResponse;
  paymentInfo$: Observable<PaymentMethodResponse>;

  phoneNumberMask = [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  isGift: boolean;
  numSteps: number = 3;
  selectedStep: number = 1;

  dealData: DealData;
  hasRestriction: boolean = false;
  isLoading: boolean = true;

  mobilemode!: Boolean | true;
  smallestScreen: Boolean | false;
  url: string =
    'https://applink.moola.com/?action=merchantPage&referenceSource=QRCode&referenceType=Merchant&redirect=https://www.moola.com/';
  recaptchaSiteKey = environment.recaptchaSiteKey;

  constructor(
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private deviceInformationService: AngularDeviceInformationService,
    private sessionStorageService: SessionStorageService,
    private gas: GoogleAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private infoConentDialog: MatDialog
  ) {
    this.isNext = false;
  }

  ngOnInit(): void {
    this.dealData = this.sessionStorageService.getSelectedDealData();
    this.hasRestriction =
      this.dealData.restriction != null && this.dealData.restriction != '';

    if (this.sessionStorageService.getIsGift()) {
      this.numSteps = 4;
      this.selectedStep = 2;
    }
    this.buyerInfo$ = this.store.select(getBuyerInfoSelector);
    this.buyerInfo$
      .pipe(
        tap((buyerInfo) => {
          this.buyerInfo = buyerInfo;
        }),
        takeUntil(this.notifier)
      )
      .subscribe();

    this.userResponse$ = this.store.select(getUserResponseSelector);
    this.userResponse$
      .pipe(
        tap((userResponse) => {
          this.userResponse = userResponse;
        }),
        takeUntil(this.notifier)
      )
      .subscribe();

    this.paymentInfo$ = this.store.select(getPaymentResponseSelector);
    this.paymentInfo$
      .pipe(
        tap((paymentInfo) => {
          this.paymentInfo = paymentInfo;
        }),
        takeUntil(this.notifier)
      )
      .subscribe();

    this.buyerFormGroup = this.fb.group({
      firstName: new FormControl(
        this.buyerInfo ? this.buyerInfo.firstName : '',
        [Validators.required, Validators.maxLength(100)]
      ),
      lastName: new FormControl(this.buyerInfo ? this.buyerInfo.lastName : '', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      recipientEmail: new FormControl(
        this.buyerInfo ? this.buyerInfo.email : '',
        [
          Validators.required,
          Validators.pattern(
            /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/
          ),
          this.whitespaceTrimmer,
        ]
      ),
      confirmEmail: new FormControl(
        this.buyerInfo ? this.buyerInfo.email : '',
        [
          Validators.required,
          this.emailConfirming.bind(this),
          this.whitespaceTrimmer,
        ]
      ),
      phoneNumber: new FormControl(
        this.buyerInfo ? this.buyerInfo.phoneNumber : '',
        [
          Validators.required,
          Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}|\d{10}$/),
          this.whitespaceTrimmer,
        ]
      ),
    });

    const recipientEmailControl =
      this.buyerFormGroup.controls['recipientEmail'];
    const confirmEmailControl = this.buyerFormGroup.controls['confirmEmail'];

    this.recipientEmailSub = recipientEmailControl.valueChanges.subscribe(
      (changes) => {
        confirmEmailControl.updateValueAndValidity();
      }
    );

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (!this.isNext) {
      this.gas.eventEmitter(
        this.sessionStorageService.getIsGift()
          ? globals.GC_EVENT_WEB_GIFT_LEAVE_CHECK_OUT
          : globals.GC_EVENT_WEB_LEAVE_CHECK_OUT
      );
    }

    this.notifier.next();
    this.notifier.complete();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.buyerFormGroup.controls[controlName];
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  resolved(captchaResponse: string) {
    // This method is called when the reCAPTCHA is successfully resolved.
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.recaptchaResponse = captchaResponse; // Store the response
    // Manually mark the control as touched and update its validity
    if (this.recaptchaResponse) {
      this.buyerFormGroup.setErrors(null);
    } else {
      this.buyerFormGroup.setErrors({ recaptcha: true });
    }
  }

  nextPage() {
    if (this.buyerFormGroup.invalid || !this.recaptchaResponse) {
      this.buyerFormGroup.markAllAsTouched();
      if (!this.recaptchaResponse) {
        this.buyerFormGroup.setErrors({ recaptcha: true });
      }
      return;
    }

    this.isNext = true;

    const buyerInfo: BuyerInfo = {
      firstName: this.trimWhitespace(this.buyerFormGroup.value.firstName),
      lastName: this.trimWhitespace(this.buyerFormGroup.value.lastName),
      email: this.trimWhitespace(
        this.buyerFormGroup.value.recipientEmail
      ).toLocaleLowerCase(),
      phoneNumber: this.trimWhitespace(
        this.buyerFormGroup.value.phoneNumber.replace(/[^\w]/g, '')
      ),
      recaptchaToken: this.recaptchaResponse,
    };

    if (this.paymentInfo || this.paymentInfo != null) {
      this.store.dispatch(
        PurchaseOrderActions.editBuyerInfo(buyerInfo, this.userResponse)
      );
    } else {
      this.store.dispatch(
        PurchaseOrderActions.createBuyerInfo(
          buyerInfo,
          this.sessionStorageService.getRefToken()
        )
      );
    }
    this.isSubmitBtnDisabled = true;
    setTimeout(() => {
      this.isSubmitBtnDisabled = false;
    }, 2000);
  }

  emailConfirming(fieldControl: FormControl) {
    if (this.buyerFormGroup) {
      return fieldControl.value ===
        this.buyerFormGroup.controls['recipientEmail'].value
        ? null
        : { notEqual: true };
    }
    return null;
  }

  trimWhitespace(str: string) {
    return str.replace(/\s+$/, '');
  }

  whitespaceTrimmer(control: AbstractControl): { [key: string]: any } | null {
    const trimmedValue = control.value?.trim();

    if (trimmedValue !== control.value) {
      control.setValue(trimmedValue);
    }

    return null;
  }

  downloadBtnClicked() {
    var eventValue: string = 'Home Page';

    if (this.activatedRoute.routeConfig.path.includes('merchant')) {
      eventValue = 'Merchant Page';
    }

    if (this.activatedRoute.routeConfig.path.includes('search')) {
      eventValue = 'Search Page';
    }

    if (!this.mobilemode) {
      this.infoConentDialog.open(MerchantQrCodeDialogComponent, {
        data: {},
        // position: {top: '150px'} ,
        closeOnNavigation: true,
        panelClass: 'banner-header',
      });
    } else {
      this.redirectToApp();
    }
  }

  redirectToApp() {
    window.location.href = this.url;
  }

  checkGridColumns() {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (
          result.breakpoints[Breakpoints.XSmall] ||
          result.breakpoints[Breakpoints.Small]
        ) {
          this.mobilemode = true;
          this.smallestScreen = true;
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.mobilemode = true;
          this.smallestScreen = false;
        } else if (
          result.breakpoints[Breakpoints.Large] ||
          result.breakpoints[Breakpoints.XLarge]
        ) {
          this.mobilemode = false;
          this.smallestScreen = false;
        }
      });
  }
}
