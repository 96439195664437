// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: '${APP_ENV_PRODUCTION}',
  b2CServer: 'https://web-api.moola.com',
  moolaImagesServer: 'https://api.moola.com/assets/images/',
  marketplaceURL: 'https://www.moola.com',
  googleAnlyticsId: 'G-4QTRZ6TBCF',
  recaptchaSiteKey: '6LfQwQ4rAAAAAGhH_4qhqh62HSLTwVf2CPmJKQer',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
